import React from "react";
import Layout from "../components/layout";
import productImage from "../assets/images/ver.png";
import MyButton from "../components/button";
import { navigate } from "gatsby";
import background from "../assets/images/backpat.png";
import { Link } from "gatsby";
const Product = () => {
  const futures = [
    "علاج الامساك",
    "علاج الانتفاخ",
    "علاج الغازات",
    "علاج الخمول بسبب القولون",
    "علاج ضيق التنفس الحاصل بسبب القولون",
  ];
  return (
    <Layout pageTitle="فيرتكس">
      <div style={styles.bButton}>
        سعر المنتج 57$ شامل اجور الشحن
        <MyButton onClick={() => navigate("/form")}>اطلبه الان</MyButton>
        <div style={styles.more}>
        <span>للمزيد من المعلومات حول منتج فيرتكس </span>
        <Link style={styles.btn} to="/details">اضغط هنا</Link>
        </div>
      </div>

      <div style={styles.body}>
        <div style={styles.container}>
          <h3>أهلا بك</h3>
          <h1>سوف نشرح لك عن منتج فيرتكس لعلاج القولون</h1>
          <p>
            المستخلص من قلب الطبيعة الحل الأكيد والنهائي لالتهاب القولون يعمل
            على :
          </p>

          {futures.map((element, index) => {
            return (
              <div key={index} style={styles.cards}>
                <span style={styles.answerTitle}>
                  <svg
                    style={styles.answerIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <div>{element}</div>
                </span>
              </div>
            );
          })}

          <img style={styles.image} src={productImage} alt="product" />
        </div>
      </div>
    </Layout>
  );
};

const styles = {
  body: {
    fontFamily: "bahij",
    minHeight: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "20px",
  },
  container: {
    padding: "20px",
    background: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(14px)",
    borderRadius: "10px",
    marginBottom: "120px",
    WebkitBackdropFilter: "blur(10px)",

  },
  image: {
    maxWidth: "100%",
    textAlign: "center",
  },
  bButton: {
    textAlign: "center",
    padding: "15px 20px",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255, 0.5)",
    WebkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    fontFamily: "bahij",
    zIndex: 10,
    borderTop: "1px solid rgba(0,0,0,0.1)",
  },
  cards: {
    zIndex: "100",
  },
  answerTitle: {
    padding: "7px 0px",
    color: "#000",
    borderRadius: "500px",
    fontSize: "17px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  answerIcon: {
    width: "23px",
    marginLeft: "3px",
    color: "green",
  },
  btn:{
    marginRight: "5px",
  },
  more: {
    marginTop: "4px",
    fontSize: "15px"
  }
};

export default Product;
